export const LogLevel = {
  NONE: 0,
  ERROR: 1,
  WARNING: 2,
  INFO: 3,
  LOG: 4,
  DEBUG: 5,
  TRACE: 6,
};


export function getTrace() {
  const e = new Error;
  e.name = 'Trace';
  e.stack = e.name+':\n'+e.stack.split('\n').slice(2).join('\n');
  return e;
}

// console.trace('load logger');

export class Logger
{
  /** @type {Logger} */
  static _instance = undefined;
  _showTrace = false;
  _logLevel = LogLevel.ERROR;

  /**
   * @return {Logger}
   */
  static getInstance() {
    if (this._instance === undefined) {
      this._instance = new this();
    }
    return this._instance;
  }

  constructor(logLevel) {
    // console.trace('create logger', logLevel);
    if (typeof logLevel === 'number') {
      this.logLevel = logLevel;
    }
  }

  get logLevel() {
    return this._logLevel;
  }
  set logLevel(level) {
    this.setLogLevel(level);
  }

  setLogLevel(level) {
    switch(level) {
      case LogLevel.NONE: this._logLevel = LogLevel.NONE; break;
      case LogLevel.ERROR: this._logLevel = LogLevel.ERROR; break;
      case LogLevel.WARNING: this._logLevel = LogLevel.WARNING; break;
      case LogLevel.LOG: this._logLevel = LogLevel.LOG; break;
      case LogLevel.INFO: this._logLevel = LogLevel.INFO; break;
      case LogLevel.DEBUG: this._logLevel = LogLevel.DEBUG; break;
      case LogLevel.TRACE:
        this._logLevel = LogLevel.TRACE;
        this._showTrace = true;
        break;
      default:
        throw new Error(`Unknown LogLevel "${level}"`);
    }
    return this;
  }

  get showTrace() {
    return this._showTrace;
  }
  set showTrace(showTrace) {
    this._showTrace = !!showTrace;
  }

  error(...messages) {
    if (this._logLevel < LogLevel.ERROR) return this;
    if (this._showTrace) {
      console.error('[error]', ...messages, '\n', getTrace());
    } else {
      console.error('[error]', ...messages);
    }
    return this;
  }
  errorTrace(...messages) {
    if (this._logLevel < LogLevel.ERROR) return this;
    console.error('[error]', ...messages, '\n', getTrace());
    return this;
  }

  warn(...messages) {
    if (this._logLevel < LogLevel.WARNING) return this;
    if (this._showTrace) {
      console.warn('[ warn]', ...messages, '\n', getTrace());
    } else {
      console.warn('[ warn]', ...messages);
    }
    return this;
  }
  warnTrace(...messages) {
    if (this._logLevel < LogLevel.WARNING) return this;
    console.warn('[ warn]', ...messages, '\n', getTrace());
    return this;
  }

  log(...messages) {
    if (this._logLevel < LogLevel.LOG) return this;
    if (this._showTrace) {
      console.log('[  log]', ...messages, '\n', getTrace());
    } else {
      console.log('[  log]', ...messages);
    }
    return this;
  }
  logTrace(...messages) {
    if (this._logLevel < LogLevel.LOG) return this;
    console.log('[  log]', ...messages, '\n', getTrace());
    return this;
  }

  info(...messages) {
    if (this._logLevel < LogLevel.INFO) return this;
    if (this._showTrace) {
      console.info('[ info]', ...messages, '\n', getTrace());
    } else {
      console.info('[ info]', ...messages);
    }
    return this;
  }
  infoTrace(...messages) {
    if (this._logLevel < LogLevel.INFO) return this;
    console.info('[ info]', ...messages, '\n', getTrace());
    return this;
  }

  debug(...messages) {
    if (this._logLevel < LogLevel.DEBUG) return this;
    if (this._showTrace) {
      console.debug('[debug]', ...messages, '\n', getTrace());
    } else {
      console.debug('[debug]', ...messages);
    }
    return this;
  }
  debugTrace(...messages) {
    if (this._logLevel < LogLevel.DEBUG) return this;
    console.debug('[debug]', ...messages, '\n', getTrace());
    return this;
  }

  trace(...messages) {
    if (this._logLevel < LogLevel.TRACE) return this;
    console.trace('[trace]', ...messages);
    return this;
  }

}

/**
 * work around dependency bug
 * @see https://github.com/bitrix-tools/cli/issues/33
 */
export function exportGlobal() {
  global.dvt = global.dvt || {};
  global.dvt.tracking = global.dvt.tracking || {};
  global.dvt.logger = global.dvt.logger || {
    LogLevel, Logger, getTrace
  };
}

export default Logger;
